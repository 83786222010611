import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import nameFilter from "@/store/modules/locationsOverview/filters/nameFilter.js";
import groupFilter from "@/store/modules/locationsOverview/filters/groupFilter.js";
import locationCodeFilter from "@/store/modules/locationsOverview/filters/locationCodeFilter.js";
import locationShortNameFilter from "@/store/modules/locationsOverview/filters/locationShortNameFilter.js";
import isAllocationAllowedFilter from "@/store/modules/locationsOverview/filters/isAllocationAllowedFilter.js";
import isActiveFilter from "@/store/modules/locationsOverview/filters/isActiveFilter.js";
import inStoreLocatorFilter from "@/store/modules/locationsOverview/filters/inStoreLocatorFilter.js";
import drivingRoutes from "@/store/modules/locationsOverview/drivingRoutes.js";
import drivingRoutesExceptions from "@/store/modules/locationsOverview/drivingRoutesExceptions.js";
import openingHours from "@/store/modules/locationsOverview/openingHours.js";
import openingHoursExceptions from "@/store/modules/locationsOverview/openingHoursExceptions.js";
import entityService from "@/services/EntityService.js";

const locationOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    nameFilter,
    groupFilter,
    locationCodeFilter,
    locationShortNameFilter,
    isAllocationAllowedFilter,
    isActiveFilter,
    inStoreLocatorFilter,
    drivingRoutes,
    drivingRoutesExceptions,
    openingHours,
    openingHoursExceptions
  },
  state: {
    ...tableState,
    pagination: {itemsPerPage: 20, itemsPage: 1},
    footerProps: {"items-per-page-options": [10, 20, 40, 80]},
    location: null
  },
  mutations: {
    ...tableMutations,
    SET_LOCATION(state, location) {
      state.location = location;
    },
    EDIT_ITEM(state, item) {
      console.log(item);
      state.items = state.items.map(i => i.id === item.id ? item : i);
      state.item = item;
    },
  },
  actions: {
    ...tableActions,
    fetchItems({state, commit}, refresh = false) {
      if (refresh || state.items.length === 0) {

        commit("SET_LOADING", true);
        const params = {pagination: state.pagination, sorting: state.sorting, search: state.search};

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        entityService.locations.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });

      }

    },
    selectLocation({ commit }, item) {
      commit("SET_ITEM", item);
      commit("SET_LOCATION", item);
    },
    edit({commit}, location) {
      return new Promise((resolve, reject) => {
        if (location.locationLine2 === "") {
          location.locationLine2 = null;
        }
        location.priority = (location.priority?.length === 0) ? null : Number.parseInt(location.priority);
        location.longitude = (location.longitude?.length === 0) ? null : location.longitude;
        location.latitude = (location.latitude?.length === 0) ? null : location.latitude;

        entityService.locations.update(location.code, location)
          .then(() => {
            commit("SET_LOCATION", location);
            commit("EDIT_ITEM", location);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    add({commit}, location) {
      return new Promise((resolve, reject) => {
        location.priority = (location.priority === null || location.priority.length === 0) ? null : Number.parseInt(location.priority);
        location.longitude = (location.longitude === null ||location.longitude.length === 0) ? null : location.longitude;
        location.latitude = (location.latitude === null || location.latitude.length === 0) ? null : location.latitude;

        entityService.locations.create(location)
          .then((data) => {
            commit("SET_LOCATION", data);
            commit("ADD_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    patch({commit}, location) {
      return new Promise((resolve, reject) => {
        entityService.locations.patch(location.code, location, { headers: { "content-type": "application/merge-patch+json" }})
            .then((location) => {
              commit("SET_LOCATION", location);
              commit("EDIT_ITEM", location);
              resolve();
            })
            .catch(err => {
              reject(err["hydra:description"]);
            });
      });
    },
  },
  getters: {
    ...tableGetters,
    getLocationById: state => id => {
      return state.items.find(item => item.id === id);
    }
  }
};

export default locationOverview;
