import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import entityService from "@/services/EntityService.js";

const drivingRoutes = {
  namespaced: true,
  state: {
    ...tableState,
    drivingRoute: null,
    location: null,
    pagination: {itemsPerPage: 20, itemsPage: 1},
    footerProps: {"items-per-page-options": [10, 20, 40, 80]},
    sorting: { sortBy: ["dayOfTheWeek", "date", "time"], sortDesc: [false, false, false] }
  },
  mutations: {
    ...tableMutations,
    SET_DRIVING_ROUTE(state, drivingRoute) {
      state.drivingRoute = drivingRoute;
    },
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        const params = {
          pagination: context.state.pagination,
          sorting: context.state.sorting,
          search: [{ column: "exists[date]", value: false }]
        };

        const location = context.rootState.locationsOverview.location;

        entityService.locations.getSubResource(location.code, 'driving_routes', params)
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    searchItems(context) {
      context.dispatch("fetchItems", true);
    },
    addDrivingRoute(context, drivingRoute) {
      return new Promise((resolve, reject) => {
        entityService.drivingRoutes.create(drivingRoute)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    setDrivingRoute(context, drivingRoute) {
      context.commit(
        "SET_DRIVING_ROUTE",
        JSON.parse(JSON.stringify(drivingRoute))
      );
    },
    editDrivingRoute(context, drivingRoute) {
      return new Promise((resolve, reject) => {
        entityService.drivingRoutes.update(drivingRoute.id, drivingRoute)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    deleteDrivingRoute(context, drivingRoute) {
      return new Promise((resolve, reject) => {
        entityService.drivingRoutes.delete(drivingRoute.id)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    }
  },
  getters: {
    ...tableGetters
  }
};

export default drivingRoutes;
