import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components"
import entityService from "@/services/EntityService.js";

const openingHoursExceptions = {
  namespaced: true,
  state: {
    ...tableState,
    exception: {
      open: false,
      startDate: null,
      endDate: null,
      startTime1: null,
      startTime2: null,
      startTime3: null,
      endTime1: null,
      endTime2: null,
      endTime3: null,
      location: null,
      reason: "",
      code: null
    },
    pagination: {itemsPerPage: 20, itemsPage: 1},
    footerProps: {"items-per-page-options": [10, 20, 40, 80]},
  },
  mutations: {
    ...tableMutations,
    SET_EXCEPTION(state, exception) {
      state.exception = exception;
    }
  },
  actions: {
    ...tableActions,
    setException(context, location) {
      context.commit("SET_EXCEPTION", location);
    },
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);

        const params = {
          pagination: context.state.pagination,
          sorting: context.state.sorting
        };

        const location = context.rootState.locationsOverview.location;

        entityService.locations.getSubResource(location.code, 'opening_hours_exceptions', params)
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    editException({dispatch}, exception) {
      return new Promise((resolve, reject) => {
        entityService.openingHoursExceptions.update(exception['id'], exception)
          .then(() => {
            dispatch("fetchItems", true)
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    createException({dispatch}, exception) {
      return new Promise((resolve, reject) => {
        entityService.openingHoursExceptions.create(exception)
          .then(() => {
            dispatch("fetchItems", true)
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    deleteException(context, exception) {
      return new Promise((resolve, reject) => {
        entityService.openingHoursExceptions.delete(exception['id'])
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    deleteAllExceptions(context, exception) {
      return new Promise((resolve, reject) => {
        entityService.openingHoursExceptions.deleteAll(exception.code)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    syncExceptions(context, request) {
      return new Promise((resolve, reject) => {
        entityService.openingHoursExceptions[request.method](request.data)
          .then(() => {
            return resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
  },
  getters: {
    ...tableGetters,
    exception: (state) => state.exception
  }
};

export default openingHoursExceptions;
