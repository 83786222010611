import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";

Vue.use(VueRouter);

const routes = [
  {
    path: "/locations",
    name: "locationsOverview",
    menuTitle: i18n.tc("locations.title", 2),
    menuIcon: "mdi-store",
    showIcon: false,
    component: () => import("@/views/LocationsView.vue"),
    active: true
  },
  {
    path: "/",
    redirect: "/locations",
    showIcon: false,
    active: false
  },
  {
    path: "/location-groups",
    name: "locationGroupsOverview",
    menuTitle: i18n.tc("location-groups.title", 2),
    menuIcon: "mdi-home-group",
    showIcon: false,
    component: () => import("@/views/locationGroupsView.vue"),
    active: true
  },
  {
    path: "/users",
    name: "usersOverview",
    menuTitle: i18n.tc("users.title", 2),
    menuIcon: "mdi-account-outline",
    showIcon: false,
    component: () => import("@/views/UsersView.vue"),
    active: true
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
